import { forwardRef } from "react";

import { useField } from "formik";
import InputMask from "react-input-mask";
import styled from "styled-components";

const TextFieldInput = styled.input`
  padding: 14px 0;
  border: 0;
  outline: none;
  border-bottom: 1px solid ${(p) => p.theme.colors.black};
  width: 100%;
  font-size: 16px;
  background: transparent;

  &::placeholder {
    color: #717171;
    font-size: 14px;
  }
`;

const TextFieldError = styled.div`
  color: #ff5252;
  font-size: 12px;
  padding: 4px 0;
  position: absolute;
  top: 5.1rem;
  left: 0;
`

const TextField = ({ name, ...rest }, ref) => {
  const [field, meta] = useField(name);

  return (
    <div css="width: 100%; position: relative ">
      <InputMask mask="+1 (999) 999-9999" {...field}>
        {(inputProps) => <TextFieldInput ref={ref} {...inputProps} {...rest} />}
      </InputMask>
      {meta.touched && meta.error && (
        <TextFieldError>{meta.error}</TextFieldError>
      )}
    </div>
  );
};

export default forwardRef(TextField);
