import * as Yup from "yup"
export function getTop(element) {
  return (
    element.getBoundingClientRect().top + document.documentElement.scrollTop
  )
}

export function transformSizes(element, sizes) {
  const top = getTop(element)
  const height = element.offsetHeight

  return sizes.map(size => top + (height * size) / 100)
}

export function getCanonicalUrl(str) {
  let url = "https://ronary.com"

  if (str) {
    url += `/${str}`
  }
  return url
}

export const range = (min, max) => {
  return Array(max - min + 1)
    .fill(0)
    .map((_, i) => i + min)
}

export const getMaskValidation = (length, errMessage) =>
  Yup.string().test("len", errMessage, val => {
    return val ? val.replace(/[^a-zA-Z0-9.]/g, "").length === length : true
  })


export const getLeadYupSchema = () => {
  return Yup.object().shape({
    firstName: Yup.string()
      .required("First Name is required!")
      .min(2, "First Name should be at least 2 chars length"),
    lastName: Yup.string()
      .required("Last Name is required!")
      .min(2, "Last Name should be at least 2 chars length"),
    phone: getMaskValidation(11, "invalid phone number").required(
      "Phone is required!"
    ),
    email: Yup.string()
      .email("Invalid email")
      .required("Please enter your email"),
  })
}